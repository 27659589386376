<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/services')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("YANDEX_BUSINESS") }}

  v-main(flat)
    v-container.pt-0(id="yandex" style="max-width: 450px")
      v-row
        v-col.pt-5(align="center")
          v-img.rounded-xl(:src="`/static/yandex-business-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("реклама_для_бизнеса") }}
          .subtitle-1.my-2 {{ $t("занимайтесь_бизнесом_клиентов_привлечет_яндекс") }}
          .headline.mb-2.mt-4 {{ $t("о_сервисе") }}
          .subtitle-1.my-2 {{ $t("яндекс_бизнес_автоматически_создаст_и_настроит_объявления_ваша_реклама_появится_там_где_есть_ваши_потенциальные_клиенты") }}
          ul
            li.subtitle-1 {{ $t("яндекс") }}
            li.subtitle-1 {{ $t("яндекс_поиск") }}
            li.subtitle-1 {{ $t("яндекс_карты") }}

      v-row
        v-col
          v-list.re-v-list-fulltext.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-television
              v-list-item-content
                v-list-item-title {{ $t("простой_для_понимания_интерфейс") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-timer-sand-complete
              v-list-item-content
                v-list-item-title {{ $t("яндекс_все_настроит_за_вас") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-shuffle-variant
              v-list-item-content
                v-list-item-title {{ $t("несколько_точек_размещения_рекламы") }}

      v-row
        v-col
          v-btn(color="secondary" block @click="openYandexBusiness()") {{ $t("попробовать") }}

      v-row
        v-col
          .body-2.mb-2 {{ $t("рекомендации") }}
          ul
            li.body-2 {{ $t("срок_рекламной_кампании_должен_быть_не_менее_чем_3_месяца_это_лучший_период_для_оптимизации_рекламы_для_вашей_ца") }}
            li.body-2 {{ $t("для_получения_лучшего_результата_рекомендуется_использовать_бюджет_от_тенге_в_месяц") }}
            li.body-2 {{ $t("рекламные_объявления_с_фотографиями_лучше_привлекают_внимание_потенциальных_покупателей_не_забудьте_загрузить_их_в_сервис_если_они_имеются") }}

</template>
<script>
import { mapActions, mapState } from 'vuex'
import { getLocale } from '../../i18n'

export default {

  data: () => ({
    yandexBusinessIAB: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    locale() {
      return getLocale()
    },
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
    }),

    openYandexBusiness() {
      this.$openLink('https://yandex-business.rekassa.kz', '_system')
      this.analyticsLogEvent({ eventName: 're_partner_yandex_business_clicked', eventProperties: { phone: this.user.phone } })
    },

  },
}
</script>

<style lang="stylus">
</style>
