import axios from 'axios'
import { getString } from 'firebase/remote-config'
import { getLocale } from '../../i18n/index'

const tools = {
  namespaced: true,

  state: {
    firebaseApp: null,
    firebaseRemoteConfig: null,

    cashRegisterSwitch: false,
    snackbar: '',
    confirm: {
      show: false,
      title: null,
      icon: null,
      text: null,
      resolveText: null,
      rejectText: null,
      persistent: false,
      promise: null,
      deferred: null,
      resolveButtonColor: null,
      rejectButtonColor: null,
      resolveCallback: null,
      rejectCallback: null,
      resolveLoading: false,
      rejectLoading: false,
    },
    keyListenerEnabled: true,
    appInitialized: false,
    connectionWarning: false,
    cordovaBuildVersion: null,
    ticketLocale: getLocale(),
    helpCrunchUnreadCount: 0,
    ofdRegIframe: false,
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    // Firebase stuff
    setFirebaseApp({ commit }, payload) {
      commit('set', { type: 'firebaseApp', value: payload })
    },

    setFirebaseRemoteConfig({ commit }, payload) {
      commit('set', { type: 'firebaseRemoteConfig', value: payload })
    },

    firebaseRemoteConfigGetString({ state }, payload) {
      if (window.cordova) {
        return new Promise((resolve) => {
          window.FirebasePlugin.getValue(payload, (value) => {
            resolve(value)
          }, () => {})
        })
      }
      return new Promise((resolve) => {
        const value = getString(state.firebaseRemoteConfig, payload)
        resolve(value)
      })
    },

    // Other stuff
    showCashRegisterSwitch({ commit }, payload) {
      commit('set', { type: 'cashRegisterSwitch', value: payload })
    },

    showSnackbar({ commit }, payload) {
      commit('set', { type: 'snackbar', value: payload })
    },

    showConfirm({ commit, state }, payload) {
      let deferred = null
      const promise = new Promise(((resolve, reject) => {
        deferred = { resolve, reject }
      }))
      commit('set', {
        type: 'confirm',
        value: {
          show: true,
          title: payload.title,
          icon: payload.icon,
          text: payload.text,
          resolveText: payload.resolveText,
          rejectText: payload.rejectText,
          persistent: payload.persistent,
          promise,
          deferred,
          resolveButtonColor: payload.resolveButtonColor ? payload.resolveButtonColor : 'primary',
          rejectButtonColor: payload.rejectButtonColor ? payload.rejectButtonColor : 'white',
          resolveCallback: payload.resolveCallback,
          rejectCallback: payload.rejectCallback,
        },
      })
      return state.confirm.promise
    },

    setConfirmLoading({ commit, state }, payload) {
      commit('set', {
        type: 'confirm',
        value: {
          ...state.confirm,
          [payload.type]: payload.value,
        },
      })
    },

    hideConfirm({ state, commit }) {
      commit('set', {
        type: 'confirm',
        value: {
          ...state.confirm,
          show: false,
        },
      })
    },

    clearConfirm({ commit }) {
      commit('set', {
        type: 'confirm',
        value: {
          show: false,
          title: null,
          icon: null,
          text: null,
          resolveText: null,
          rejectText: null,
          persistent: false,
          promise: null,
          deferred: null,
          resolveButtonColor: null,
          rejectButtonColor: null,
          resolveCallback: null,
          rejectCallback: null,
          resolveLoading: false,
          rejectLoading: false,
        },
      })
    },

    setKeyListenerStatus({ commit }, payload) {
      commit('set', { type: 'keyListenerEnabled', value: payload })
    },

    setAppInitialized({ commit }, payload) {
      commit('set', { type: 'appInitialized', value: payload })
    },

    setTicketLocale({ commit }, payload) {
      commit('set', { type: 'ticketLocale', value: payload })
    },

    setConnectionWarning({ commit }, payload) {
      commit('set', { type: 'connectionWarning', value: payload })
    },

    setCordovaBuildVersion({ commit }, payload) {
      commit('set', { type: 'cordovaBuildVersion', value: payload })
    },

    setHelpCrunchUnreadCount({ commit }, count) {
      commit('set', { type: 'helpCrunchUnreadCount', value: count })
    },

    copyToClipboard({}, payload) {
      const el = document.createElement('textarea')
      el.addEventListener('focusin', e => e.stopPropagation())
      el.value = payload
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false
      el.select()
      document.execCommand('copy')
      // document.body.removeChild(el) // Remove the <textarea> element
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },

    uploadImage({ }, payload) {
      return axios.post('https://api.imgbb.com/1/upload?key=538fed268d627ecf9b18e9ec0c71f916', payload.data)
    },

    showOfdRegIframe({ commit }, payload) {
      commit('set', { type: 'ofdRegIframe', value: payload })
    },

  },
}

export default tools
