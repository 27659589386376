<template lang="pug">
v-main(flat)
  v-container.receipt.mt-2(style="max-width: 400px !important")
    template(v-if="!preTicket && ['MONEY_PLACEMENT_DEPOSIT', 'MONEY_PLACEMENT_WITHDRAWAL'].indexOf(ticketData.operation) === -1")
      v-row(v-if="preferences.logoUrl")
        v-col(align="center")
          img.logo(:src="preferences.logoUrl")

      template(v-if="preferences.advertisingText")
        v-row(justify="center")
          v-col.text-center.secondary-text(cols="11")
            div {{ preferences.advertisingText }}
              v-divider.my-3

    v-row
      v-col
        h1.headline.font-weight-bold.text-center {{ organization.businessName }}
        h2.subtitle-1.text-center {{ $tre("бин_иин", ticketLocale) }}: {{ organization.businessId }}
        h2.subtitle-2.text-center {{ cashRegister.pos.address }}

    v-row.my-2
      v-col.text-uppercase
        template(v-if="preTicket")
          h2.title.text-center {{ $tre("предварительный_чек", ticketLocale) }}
        template(v-else)
          template(v-if="isFiscal")
            h2.title.text-center {{ $tre("фискальный_чек", ticketLocale) }}
          template(v-else)
            h2.title.text-center(style="background: red") {{ $tre("нефискальный_чек", ticketLocale) }}
              template(v-if="isTest")
                h2.title.text-center(style="background: red") {{ $tre("для_тестирования", ticketLocale) }}
              template(v-else)
                h2.title.text-center(style="background: red") {{ $tre("для_демонстрации", ticketLocale) }}

    template(v-if="!preTicket")
      v-divider.my-3

      v-row(v-if="ticket.offline")
        v-col.text-uppercase {{ $tre("автономный", ticketLocale) }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre('operationType.' + ticketData.operation, ticketLocale) }}:
            div(v-if="ticket.shiftDocumentNumber") №{{ ticket.shiftDocumentNumber }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("смена", ticketLocale) }}:
            div {{ ticket.shiftNumber }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("дата", ticketLocale) }}:
            div(v-if="ticketData.dateTime") {{ ticketData.dateTime.value | moment("DD-MM-YYYY") }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("время", ticketLocale) }}:
            div(v-if="ticketData.dateTime") {{ ticketData.dateTime.value | moment("HH:mm:ss") }}
      v-row(v-if="commodityItems")
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("фп", ticketLocale) }}:
            div {{ ticket.offline ? ticket.offlineTicketNumber : ticket.ticketNumber }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("кассир", ticketLocale) }}:
            div {{ ticketData.operator ? (ticketData.operator.name ? ticketData.operator.name : ticketData.operator.code) : '' }}
      v-row(v-else)
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("кассир", ticketLocale) }}:
            div {{ ticketData.operator ? (ticketData.operator.name ? ticketData.operator.name : ticketData.operator.code) : '' }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("рнм", ticketLocale) }}:
            div {{ cashRegister.registrationNumber }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("знм", ticketLocale) }}:
            div {{ cashRegister.serialNumber }}

    v-divider.my-3

    template(v-if="isDuplicate")
      v-row
        v-col
          h1.headline.text-center.text-uppercase {{ $tre("дубликат", ticketLocale) }}
      v-divider.my-3

    v-list(two-line v-for="(item, $index) in commodityItems" :key="'c_' + $index")
      v-list-item
        v-list-item-content
          v-list-item-title {{ $index + 1 }}. {{ preferences.barcodeInPositions && item.commodity.barcode ? item.commodity.barcode : '' }} {{ item.commodity.name }}
          v-list-item-title(v-if="item.commodity.sku") {{ $tre("артикул") }}: {{ item.commodity.sku }}
      v-list-item(v-if="item.commodity.exciseStamp")
        v-list-item-content
          v-list-item-subtitle {{ item.commodity.exciseStamp }}
      v-list-item
        v-list-item-content
          v-list-item-subtitle {{ item.commodity.realQuantity | numeral('0,0.0[00]') }} {{ item.commodity.unitType ? $tre('unitType.' + item.commodity.unitType, ticketLocale) : `(${$tre("код_эсф")}: ${item.commodity.measureUnitCode})` }} &times; {{ item.commodity.price.value | numeral('0,0.00') }} ₸
        v-list-item-action
          v-list-item-action-text.body-1 {{ item.commodity.sum.value | numeral('0,0.00') }} ₸
      v-list-item(v-if="item.commodity.discountType")
        v-list-item-content
          v-list-item-subtitle {{ $tre("скидка", ticketLocale) }} {{ item.commodity.discountType === 'PERCENTAGE' ? `(${ item.commodity.discountPercentage }%)` : '' }}
        v-list-item-action
          v-list-item-action-text.body-1 - {{ item.commodity.discountValue | numeral('0,0.00') }} ₸
      v-list-item(v-if="item.commodity.markupType")
        v-list-item-content
          v-list-item-subtitle {{ $tre("наценка", ticketLocale) }} {{ item.commodity.markupType === 'PERCENTAGE' ? `(${ item.commodity.markupPercentage }%)` : '' }}
        v-list-item-action
          v-list-item-action-text.body-1 + {{ item.commodity.markupValue | numeral('0,0.00') }} ₸
      v-list-item
        v-list-item-content
          v-list-item-subtitle {{ $tre("стоимость", ticketLocale) }}
        v-list-item-action
          v-list-item-action-text.body-1 {{ item.commodity.total | numeral('0,0.00') }} ₸
      v-list-item(v-if="item.commodity.taxes && item.commodity.taxes[0]")
        v-list-item-content
          v-list-item-subtitle {{ $tre("в_т_ч_ндс", ticketLocale) }} {{ item.commodity.taxes[0].percent / 1000 }}%
        v-list-item-action
          v-list-item-action-text.body-1 {{ item.commodity.taxes[0].sum.value | numeral('0,0.00') }} ₸

    v-divider.my-3(v-if="commodityItems")

    v-list
      v-list-item
        v-list-item-content
          v-list-item-title.headline.text-uppercase {{ $tre("итого", ticketLocale) }}
        v-list-item-action
          v-list-item-action-text.display-2 {{ this.ticketData.amounts.total.value | numeral('0,0.00') }} ₸

    v-list.totals
      template(v-if="ticketData.amounts && ticketData.amounts.discount")
        v-list-item
          v-list-item-content
            v-list-item-title {{ $tre("сумма_без_скидки", ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.body-1 {{ ticketData.amounts.discount.sumWithoutDiscount | numeral('0,0.00') }} ₸

        v-list-item
          v-list-item-content
            v-list-item-title {{ $tre("скидка", ticketLocale) }} {{ ticketData.amounts.discount.type === 'PERCENTAGE' ? `(${ ticketData.amounts.discount.percentage }%)` : '' }}
          v-list-item-action
            v-list-item-action-text.body-1 - {{ ticketData.amounts.discount.value | numeral('0,0.00') }} ₸

      template(v-if="ticketData.amounts && ticketData.amounts.markup")
        v-list-item
          v-list-item-content
            v-list-item-title {{ $tre("сумма_без_наценки", ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.body-1 {{ ticketData.amounts.markup.sumWithoutMarkup | numeral('0,0.00') }} ₸

        v-list-item(v-if="ticketData.amounts && ticketData.amounts.markup")
          v-list-item-content
            v-list-item-title {{ $tre("наценка", ticketLocale) }} {{ ticketData.amounts.markup.type === 'PERCENTAGE' ? `(${ ticketData.amounts.markup.percentage }%)` : '' }}
          v-list-item-action
            v-list-item-action-text.body-1 + {{ ticketData.amounts.markup.value | numeral('0,0.00') }} ₸

      span(v-for="(payment, $index) in otherPayments" :key="'p_' + $index")
        v-list-item
          v-list-item-content
            v-list-item-title {{ $tre("paymentType." + payment.type, ticketLocale) }}
          v-list-item-action
            v-list-item-action-text.body-1 {{ payment.sum.value | numeral('0,0.00') }} ₸
        template(v-if="payment.type === 'PAYMENT_MOBILE' && ticket.extra && ticket.extra.attributes && ticket.extra.attributes.paymentType === 'KASPI_PAY'")
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre('оплачено_через_name', ticketLocale, { name: $tre('KASPI_PAY', ticketLocale) }) }}
        template(v-if="payment.type === 'PAYMENT_CARD' && ticket.extra && ticket.extra.attributes && ticket.extra.attributes.paymentType")
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre(`${ticketData.operation === 'OPERATION_SELL' ? 'оплачено_через_name' : 'возврат_через_name'}`, ticketLocale, { name: $tre(ticket.extra.attributes.paymentType, ticketLocale) }) }}
        template(v-if="payment.type === 'PAYMENT_CARD' && ticket.extra && ticket.extra.attributes && (ticket.extra.attributes.paymentType === 'ALFA_PAY' || ticket.extra.attributes.paymentType === 'HALYK_POS' || ticket.extra.attributes.paymentType === 'FFB_POS')")
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("номер_операции", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end(v-if="ticket.extra.attributes.m4bankOperationDay") {{ ticket.extra.attributes.m4bankOperationDay }}/{{ ticket.extra.attributes.m4bankTransactionNumber }}
              v-list-item-subtitle.re-text-align-end(v-else) {{ ticket.extra.attributes.m4bankTransactionNumber }}
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("дата_оплаты", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end {{ ticket.extra.attributes.m4bankDateTime | moment("DD-MM-YYYY HH:mm:ss") }}
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("реквизиты_карты", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end {{ ticket.extra.attributes.m4bankMaskedPan }}
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("организация", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end {{ ticket.extra.attributes.m4bankMerchantName }}
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("терминал", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end {{ ticket.extra.attributes.m4bankTerminalId }}
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("код_ответа", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end 0
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("код_авторизации", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end {{ ticket.extra.attributes.m4bankAuthorizationCode }}
          v-list-item
            v-list-item-content
              v-list-item-subtitle {{ $tre("rrn", ticketLocale) }}
            v-list-item-action
              v-list-item-subtitle.re-text-align-end {{ ticket.extra.attributes.m4bankRRN }}

      v-list-item(v-if="cashPayment > 0")
        v-list-item-content
          v-list-item-title {{ $tre("paymentType.PAYMENT_CASH", ticketLocale) }}
        v-list-item-action
          v-list-item-action-text.body-1 {{cashPayment | numeral('0,0.00') }} ₸

      v-list-item(v-if="ticketData.amounts && ticketData.amounts.change && ticketData.amounts.change.value > 0")
        v-list-item-content
          v-list-item-title {{ $tre("сдача", ticketLocale) }}
        v-list-item-action
          v-list-item-action-text.body-1 - {{ ticketData.amounts.change.value | numeral('0,0.00') }} ₸

      v-list-item(v-for="(tax, $index) in taxes" :key="'t_' + $index")
        v-list-item-content
          v-list-item-title {{ $tre("в_т_ч_ндс", ticketLocale) }} {{ tax.percent }}%
        v-list-item-action
          v-list-item-action-text.body-1 {{ tax.value | numeral('0,0.00') }} ₸

    template(v-if="ticketData.extensionOptions && ticketData.extensionOptions.customerIinOrBin")
      v-divider.mt-3.mb-3

      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("покупатель", ticketLocale) }}
            div {{ ticketData.extensionOptions.customerIinOrBin }}

    template(v-if="orderNumber()")
      v-divider.mt-3.mb-3

      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("номер_заказа", ticketLocale) }}
            div {{ orderNumber() }}

    template(v-if="ticketData.extensionOptions && ticketData.extensionOptions.customerEmail")
      v-divider.mt-3.mb-3

      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $tre("email", ticketLocale) }}
            div {{ ticketData.extensionOptions.customerEmail }}

    template(v-if="ticket.fdo && (ticket.offline ? ticket.offlineTicketNumber : ticket.ticketNumber)")
      v-divider.my-3

      v-row(justify="center")
        v-col.text-center.subtitle-2.secondary-text.fdo-text(cols="9")
          div {{ ticketLocale === 'ru' ? ticket.fdo.nameRu : ticket.fdo.nameKk }}
          div {{ $tre("для_проверки_чека_отсканируйте_qr_код", ticketLocale) }} ({{ ticket.fdo.url }})
        v-col.pl-0(cols="3")
          div.d-flex.justify-center.my-1(v-if="qrCode")
            div.re-qrcode(style="cursor: pointer" @click="$openLink(qrCode, '_system')")
              qrcode(:value="qrCode" size="60" level="L" renderAs="svg")
    template(v-if="!preTicket")
      v-divider.my-3
      v-row
        v-col.text-center.subtitle-2.secondary-text
          div {{ $tre("rekassakz_бесплатный_онлайн_кассовый_аппарат", ticketLocale) }}

</template>
<script>
import { mapState } from 'vuex'
import qrcode from 'qrcode.vue'
import ticketMixin from '../../../mixins/ticketMixin'

export default {
  components: {
    qrcode,
  },

  mixins: [ticketMixin],

  props: {
    ticket: {
      type: Object,
      default: null,
      required: true,
    },
    cashRegister: {
      type: Object,
      default: null,
      required: true,
    },
    organization: {
      type: Object,
      default: null,
      required: true,
    },
    ticketLocale: {
      type: String,
      default: null,
      required: true,
    },
    preTicket: {
      type: Boolean,
      default: false,
      required: true,
    },
    demoTicket: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    ...mapState({
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    ticketData() {
      return this.ticket.data.ticket ? this.ticket.data.ticket : this.ticket.data.moneyPlacement
    },

    qrCode() {
      return this.ticket.qrCode ? `${this.ticket.qrCode}&l=${this.ticketLocale}` : null
    },

    isFiscal() {
      return !(process.env.VUE_APP_TEST === 'true' || this.cashRegister.status === 'TRIAL' || this.demoTicket)
    },

    isTest() {
      return process.env.VUE_APP_TEST === 'true'
    },
  },

  mounted() {
    if (localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true') {
      setTimeout(() => {
        this.$vuetify.goTo(document.getElementsByClassName('receipt')[0].offsetHeight)
      }, 500)
    }
  },
}
</script>

<style lang="stylus">
.theme--dark
  .secondary-text
    color rgba(255, 255, 255, 0.7)
.receipt
  .logo
    height 100px
  .re-qrcode
    display inline-block
    padding 1px 1px 1px 1px !important
    div
      line-height 0 !important
  .col
    padding-top 1px
    padding-bottom 1px
  .v-divider
    &.theme--dark
      border-color rgba(255, 255, 255, 0.5)
  .v-list-item
    min-height 0
    padding 0
  .v-list-item__content
    padding-top 3px
    padding-bottom 3px
  .v-list-item__action-text
    &.body-1
      font-size 1rem !important
    &.display-2
      font-size 1.9rem !important
  .v-list-item__title
    white-space normal
    &.headline
      font-size 1.9rem !important
  .totals
    .v-list-item__action-text
      &.body-1
        font-size 1rem !important
  .v-list-item__action
    margin 0px
  .theme--dark
    &.v-list-item
      .v-list-item__action-text
        color rgb(255, 255, 255)
  .theme--light
    &.v-list-item
      .v-list-item__action-text
        color rgba(0, 0, 0, 0.87)

.receipt .v-list, .receipt .v-sheet
  background-color transparent !important
  padding-top 0
  padding-bottom 0
</style>
