<template lang="pug">
div.btn-wrap
  v-btn(elevation="0" width="100%" color="reForeground darken-1" :height="btnHeight").pt-3.pb-2.px-1.py-md-6.px-md-2
    div.d-flex.flex-column.align-center.justify-center.full-width
      v-icon.flex-grow-0.mb-1.mb-md-2(:size="categoryIconSize" :class="`icon-${categoryItem.data.custom ? 'custom' : categoryItem.id}`")
      p.text-body-2.text-md-h5.text-center.px-2.text-wrap.post-desc.px-md-0 {{ categoryItem.data.name }}
  div(v-if="counter").counter 3
  v-sheet(v-if="showOptions").options.py-2.px-6
    v-btn(height="30" elevation="0" color="transparent").pa-0 {{ $t("редактировать") }}
    v-btn(height="30" elevation="0" color="transparent").pa-0.delete--text {{ $t("удалить") }}
</template>

<script>
export default {
  name: 'SelectCategoryItem',

  props: {
    categoryItem: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    counter: 0,
    showOptions: false,
  }),

  computed: {
    categoryIconSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '42' : '28'
    },

    btnHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '120' : '86'
    },
  },
}
</script>

<style lang="stylus" scoped>
::v-deep .v-btn__content
  width 100%

.full-width
  width 100%

.btn-wrap
  position relative
  background #19454E
  border-radius 12px
  height fit-content

.counter
  background #15BAAA
  position absolute
  top 0
  right 0
  border-radius 0 12px 0 12px
  padding 5px 10px
  font-size 12px

  @media screen and (min-width: 960px)
    font-size 18px

.post-desc
  width 100%
  display -webkit-box
  -webkit-line-clamp 2
  -webkit-box-orient vertical
  overflow hidden

.options
  position absolute
  z-index 5
  display flex
  flex-direction column
  background #618B94
  border-radius 12px
  top 30%
  left 20%
</style>
