<template lang="pug">
  re-pinpad(v-model="saveChangesDialog" :title="$t('сохранение_настроек')" :subtitle="$t('введите_пин-код')" :text="$t('для_включения_авто_изъятие_наличных')" :loading="loading" :errorText.sync="saveChangesErrorMessage" @action="saveChanges")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Pinpad from './PinpadDialog.vue'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  name: 'AutoWithdrawMoney',

  components: {
    're-pinpad': Pinpad,
  },

  mixins: [billsAndCoinsMixin],

  data: () => ({
    loading: false,
    saveChangesDialog: false,
    saveChangesErrorMessage: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),
  },

  mounted() {
    // Show dialog only once per month and if cash register has more than 100k in cash
    const date = new Date()
    const currentMonth = `${date.getMonth()}`
    const showedMonth = localStorage.getItem('rekassa.kz-auto-withdraw-money-showed-month')

    if (this.cashRegister?.shift?.data?.cashSum
          && !this.cashRegister.shift.open
          && this.getNumberFromBillsAndCoins(this.cashRegister.shift.data.cashSum) > 100000
          && this.configuration.withdrawMoney !== true
          && this.configuration.closeShiftScheduleWithdrawMoney !== true
          && currentMonth !== showedMonth) {
      this.showAutoWithdrawMoney()
    }
  },

  methods: {
    ...mapActions({
      saveConfiguration: 'cashRegisters/saveConfiguration',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    showAutoWithdrawMoney() {
      this.showConfirm({
        title: this.$t('новая_функция'),
        text: this.$t('авто_изъятие_наличных_при_закрытие_смены_обновляет_dotdotdot'),
        resolveText: this.$t('включить'),
        rejectText: this.$t('не_сейчас'),
        persistent: false,
      }).then(() => {
        this.saveChangesDialog = true
      })

      const date = new Date()
      const month = date.getMonth()
      localStorage.setItem('rekassa.kz-auto-withdraw-money-showed-month', month)
    },

    getModifiedConfiguration() {
      return {
        ...this.configuration,
        withdrawMoney: true,
        closeShiftScheduleWithdrawMoney: true,
      }
    },

    saveChanges(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.saveChangesDialog = false
          this.loading = false

          this.showConfirm({
            icon: 'mdi-checkbox-marked-circle-auto-outline',
            title: this.$t('авто_изъятие_включено'),
            text: this.$t('теперь_баланс_вашей_кассы_всегда_в_порядке'),
            resolveText: this.$t('замечательно'),
            persistent: false,
          })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.saveChangesErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка_не_удалось_сохранить_настройки', { error }) })
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.saveChangesDialog = false
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
