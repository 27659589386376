<template lang="pug">
v-app
  v-app-bar.rounded-b-xl.flex-grow-0.overflow-hidden(v-if="$vuetify.breakpoint.mdAndUp" app elevation="0" height="70")
    v-container.d-flex.justify-space-between.align-center.pt-5.pb-4
      img.d-md-none.d-lg-block.mr-4.re-cursor-pointer(src="/static/logo-black.png" width="80" @click="$router.push('/')")
      .d-flex.align-center.re-main-toolbar
        router-link.text-subtitle-1.font-weight-regular.mx-lg-5.mx-md-3.d-flex.align-center.text-decoration-none(v-if="!hideDashboard" to="/" exact @click.native="analyticsLogEvent({ eventName: 're_nav_main_button_click' })")
          v-icon(left size="33") mdi-home-variant
          p.mb-0.ml-1.white--text {{ $t("главная") }}
        router-link.text-subtitle-1.font-weight-regular.mx-lg-5.mx-md-3.d-flex.align-center.text-decoration-none(:to="`/shifts/${this.cashRegister.id}`" @click.native="analyticsLogEvent({ eventName: 're_nav_sales_button_click' })")
          v-icon(left size="33") mdi-finance
          p.mb-0.ml-1.white--text {{ $t("продажи") }}
        router-link.text-subtitle-1.font-weight-regular.mx-lg-5.mx-md-3.d-flex.align-center.text-decoration-none(to="/kkm" @click.native="analyticsLogEvent({ eventName: 're_nav_cash_register_button_click' })")
          v-icon(left size="33") mdi-calculator-variant
          p.mb-0.ml-1.white--text {{ $t("касса") }}
        span.text-subtitle-1.font-weight-regular.mx-lg-5.mx-md-3.d-flex.align-center.text-decoration-none.re-cursor-pointer(@click="showChat()")
          v-icon(left size="33") mdi-chat-processing
          p.mb-0.ml-1.white--text {{ $t("чат") }}
        router-link.text-subtitle-1.font-weight-regular.mx-lg-5.mx-md-3.d-flex.align-center.text-decoration-none(to="/settings" @click="analyticsLogEvent({ eventName: 're_nav_settings_button_click' })")
          v-icon(left size="33") mdi-cog-box
          p.mb-0.ml-1.white--text {{ $t("настройки") }}
      .font-weight-bold.text-subtitle-1.text-lg-h5.re-cursor-pointer(v-if="cashRegister.status === 'TRIAL'" @click="showActivation()")
        div.d-flex.align-center.justify-center.re-switch-hover
          div.text-no-wrap
            v-icon.mr-2 mdi-information-outline
            span {{ $t("зарегистрируйте_кассу") }}
            v-icon.ml-1 mdi-chevron-right
          //- span.re-register-blur
      .d-flex.align-center.font-weight-bold.text-subtitle-1.text-lg-h5.px-4.re-cursor-pointer.re-switch-hover(v-if="cashRegister.status !== 'TRIAL'" text @click="cashRegisterSwitch()")
        div.d-flex.align-center
          | {{ cashRegister.name ? cashRegister.name : organization.businessName }}
          v-icon.ml-1 mdi-chevron-right

  v-app-bar.rounded-b-xl.flex-grow-0.overflow-hidden.pl-2(v-else app elevation="0" height="60")
    .font-weight-bold.text-subtitle-1.text-lg-h5(v-if="cashRegister.status === 'TRIAL'" @click="showActivation()")
      div.d-flex.align-center.justify-center
        v-icon.mr-2 mdi-information-outline
        | {{ $t("зарегистрируйте_кассу") }}
        v-icon.ml-1 mdi-chevron-right
        //- .re-register-blur
    .d-flex.align-center.font-weight-bold.text-subtitle-1.text-lg-h5(v-if="cashRegister.status !== 'TRIAL'" @click="cashRegisterSwitch()")
      div.d-flex.align-center
        | {{ cashRegister.name ? cashRegister.name : organization.businessName }}
        v-icon.ml-1 mdi-chevron-right

  v-main
    router-view

  v-bottom-navigation.re-main-bot-nav(app v-if="!$vuetify.breakpoint.mdAndUp")
    router-link.font-weight-regular.d-flex.flex-grow-1.flex-shrink-1.align-center.flex-column(v-if="!hideDashboard" to="/" exact @click.native="analyticsLogEvent({ eventName: 're_nav_main_button_click' })")
      v-icon.flex-grow-0(size="28") mdi-home-variant
      p.mb-0.white--text {{ $t("главная") }}
    router-link.font-weight-regular.d-flex.flex-grow-1.flex-shrink-1.align-center.flex-column(:to="`/shifts/${this.cashRegister.id}`" @click.native="analyticsLogEvent({ eventName: 're_nav_sales_button_click' })")
      v-icon.flex-grow-0(size="28") mdi-finance
      p.mb-0.white--text {{ $t("продажи") }}
    router-link.re-main-bot-nav-calc.font-weight-regular.d-flex.flex-grow-1.flex-shrink-1.align-center.flex-column(to="/kkm" @click.native="analyticsLogEvent({ eventName: 're_nav_cash_register_button_click' })")
      v-icon.flex-grow-0(size="30") mdi-calculator-variant
      p.mb-0.white--text {{ $t("касса") }}
    span.font-weight-regular.d-flex.flex-grow-1.flex-shrink-1.align-center.flex-column(@click="showChat()")
      v-icon.flex-grow-0(size="28") mdi-chat-processing
      p.mb-0.white--text {{ $t("чат") }}
      v-badge(color="red" dot bordered offset-x="-5" offset-y="-32" :value="helpCrunchUnreadCount > 0")
    router-link.font-weight-regular.d-flex.flex-grow-1.flex-shrink-1.align-center.flex-column(to="/settings" @click.native="analyticsLogEvent({ eventName: 're_nav_settings_button_click' })")
      v-icon.flex-grow-0(size="28") mdi-cog-box
      p.mb-0.white--text {{ $t("настройки") }}

  //- re-activation-abuse(v-if="cashRegister.status === 'TRIAL'")

  re-ofd-payment-notifier(v-if="cashRegister.status === 'REGISTERED'")

  re-deregistered-warning(v-if="cashRegister.status === 'DEREGISTERED'")

  re-cash-registers(@setLoadingStatus="setLoadingStatus")

  re-ofd-registration(@setLoadingStatus="setLoadingStatus")

  re-auto-withdraw-money(v-if="cashRegister.status === 'REGISTERED' && showAutoWithdrawMoney")

  re-authentication-module

  v-dialog(v-model="loading" persistent overlay-opacity="0" content-class="elevation-0")
    v-container.d-flex.justify-center.py-5
      v-progress-circular(indeterminate)

</template>
<script>
import { mapState, mapActions } from 'vuex'
import OFDPaymentNotifier from './utils/OFDPaymentNotifier.vue'
import ActivationAbuse from './utils/ActivationAbuse.vue'
import CashRegisters from './settings/CashRegisters.vue'
import OFDRegistration from './utils/OFDRegistration.vue'
import DeregisteredWarning from './utils/DeregisteredWarning.vue'
import AutoWithdrawMoney from './utils/AutoWithdrawMoney.vue'
import AuthenticationModule from './utils/AuthenticationModule.vue'
import devicePush from '../utils/devicePush'
import webPush from '../utils/webPush'

export default {
  components: {
    're-ofd-payment-notifier': OFDPaymentNotifier,
    're-activation-abuse': ActivationAbuse,
    're-cash-registers': CashRegisters,
    're-ofd-registration': OFDRegistration,
    're-deregistered-warning': DeregisteredWarning,
    're-auto-withdraw-money': AutoWithdrawMoney,
    're-authentication-module': AuthenticationModule,
  },

  data: () => ({
    loading: false,
    showLockDialog: false,
    showAutoWithdrawMoney: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      helpCrunchUnreadCount: state => state.tools.helpCrunchUnreadCount,
      appInitialized: state => state.tools.appInitialized,
    }),

    // Если FreedomBox или Мой учет iframe то скрываем главную страницу
    hideDashboard() {
      return process.env.VUE_APP_FFB_POS_MODE === 'true' || window.location !== window.parent.location
    },
  },

  created() {
    this.loading = true
    this.selectCashRegister(this.cashRegister.id).then(() => {
      this.showAutoWithdrawMoney = true
      this.loading = false
    }).catch(() => {
      this.loading = false
    })

    // AppInitialize
    if (!this.appInitialized) {
      // HelpCrunch setUserId
      if (this.$isCordova()) {
        window.cordova.plugins.HelpCrunchPlugin.updateUser(() => {}, () => {}, {
          userId: this.user.phone,
        })
      } else {
        window.HelpCrunch('userAuth', { email: this.user.phone, user_id: this.user.phone })
      }

      // Analytics setUserId
      this.analyticsSetUserId(this.user.phone)

      // App rate
      if (!this.$isFFBMode() && this.$isCordova()) {
        const appLaunchCount = parseInt(localStorage.getItem('rekassa.kz-tools-appLaunchCount') || 0, 10)
        const appRated = (localStorage.getItem('rekassa.kz-tools-appRated') || 'false') === 'true'
        if (!appRated && appLaunchCount > 30) {
          setTimeout(() => {
            localStorage.setItem('rekassa.kz-tools-appRated', 'true')
            window.cordova.plugins.AppReview.requestReview()
          }, 2000)
        }
      }

      // Analytics setUserProperty fdo_type
      if (this.cashRegister.status === 'REGISTERED') {
        this.analyticsSetUserProperty({ key: 'fdo_type', value: this.cashRegister.fdo })
      }

      // Push notification subscribe
      if (this.$isCordova()) {
        devicePush.init()
      } else {
        webPush.init()
      }

      this.setAppInitialized(true)
    }
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      analyticsSetUserProperty: 'analytics/setUserProperty',
      analyticsSetUserId: 'analytics/setUserId',
      setAppInitialized: 'tools/setAppInitialized',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    cashRegisterSwitch() {
      this.showCashRegisterSwitch(true)
      this.analyticsLogEvent({ eventName: 're_nav_cash_register_switch_clicked' })
    },

    setLoadingStatus(status) {
      this.loading = status
    },

    showActivation() {
      this.$router.push('/ofdreg')
      this.analyticsLogEvent({ eventName: 're_cash_activate_block_click_button' })
    },

    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {})
      }
      this.analyticsLogEvent({ eventName: 're_nav_helpcrunch_button_click' })
    },
  },
}

</script>
<style scoped lang="stylus">
.re-main-toolbar
  .router-link-active
    p, .v-icon
      color var(--v-secondary-base) !important
  a:hover, span:hover
    opacity 0.4

.re-switch-hover
  div:hover
    opacity 0.4

.re-register-blur
  background: radial-gradient(55.83% 335% at 50.22% 236.67%, #FF1957 0%, rgba(255, 25, 87, 0) 100%)
  position absolute
  width 459px
  height 100px
  z-index -1

.re-main-bot-nav
  height 64px !important
  padding-right 16px !important
  padding-left 16px !important
  border-radius 16px 16px 0 0
  border-top 1px solid #273A3D
  background var(--v-reForeground-base)
  .v-icon
    margin-top 12px
  p
    font-size 11px
    line-height 10px
    margin-top 3px
  a
    text-decoration none !important
  .router-link-active
    p, .v-icon
      color var(--v-secondary-base) !important

  .re-main-bot-nav-calc
    background #2B4A50
    box-shadow 0px 1px 1px 0px #FFFFFF33 inset
    position relative
    height 68px
    bottom 5px
    padding-bottom 12px
    justify-content flex-end !important
    border-radius 10px 10px 0 0
</style>
