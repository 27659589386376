<template lang="pug">
div.btn-wrap
  v-btn(elevation="0" width="100%" color="reForeground darken-1" :height="btnSize" @click="$emit('click', productItem)").pt-3.pb-2.px-1.py-md-6.px-md-2
    div.d-flex.flex-column.align-center.justify-center.full-width
      v-img(:height="productImageSize" :width="productImageSize" :src="getImage(productItem)").flex-grow-0.mb-1.mb-md-2
      p.text-body-2.text-md-h5.text-center.px-2.text-wrap.post-desc.px-md-0 {{ productItem.name }}
      p.text-body-2.text-md-h5.text-center.px-2.text-wrap.post-desc.px-md-0 {{ productItem.price | numeral('0,0.[00]') }} ₸
  div.counter(v-if="quantityCounters[productItem.id] > 0") {{ quantityCounters[productItem.id] }}
  v-sheet(v-if="showOptions").options.py-2.px-6
    v-btn(height="30" elevation="0" color="transparent").pa-0 {{ $t("редактировать") }}
    v-btn(height="30" elevation="0" color="transparent").pa-0.delete--text {{ $t("удалить") }}
</template>

<script>
export default {
  name: 'SelectProductItem',

  props: {
    productItem: {
      type: Object,
      default: null,
      required: true,
    },
    quantityCounters: {
      type: Object,
      default: () => {},
      required: false,
    },
    isActive: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    showOptions: false,
    count: 1,
    currentActive: false,
  }),

  computed: {
    productImageSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '72' : '48'
    },
    btnSize() {
      return this.$vuetify.breakpoint.mdAndUp ? '172' : '110'
    },
  },

  methods: {
    getImage(productItem) {
      if (productItem.images && productItem.images.length > 0) {
        return productItem.images[0]['96x96']
      }
      return productItem.type === 'SERVICE' ? '/static/icons/service-default.png' : '/static/icons/goods-default.png'
    },

    hundleOptions() {
      this.showOptions = !this.showOptions
    },

    addCount() {
      this.count += 1
    },

    substractCount() {
      if (this.count > 0) {
        this.count -= 1
        this.currentActive = false
      }
    },
  },
}
</script>

<style scoped>
.post-desc {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.active-btn {
  background: #508793 !important;
  border-radius: 12px 12px 0 0 !important;
}

.counter-actions {
    background: #508793;
    position: absolute;
    z-index: 5;
    width: 100%;
    border-radius: 0 0 12px 12px;
}

.btn-wrap {
    position: relative;
    background: #19454E;
    border-radius: 12px;
    height: fit-content;
}

.border {
    border-radius: 4px;
}

.counter {
    background: #15BAAA;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 12px 0 12px;
    padding: 5px 10px;
    font-size: 12px;

    @media screen and (min-width: 960px) {
        font-size: 18px;
    }
}

.options {
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: column;
    background: #618B94;
    border-radius: 12px;
    top: 30%;
    left: 20%;
}

::v-deep .v-btn__content {
    flex: 1 0;
}
</style>
