import { render, staticRenderFns } from "./SelectCategoryItem.vue?vue&type=template&id=becda7ec&scoped=true&lang=pug&"
import script from "./SelectCategoryItem.vue?vue&type=script&lang=js&"
export * from "./SelectCategoryItem.vue?vue&type=script&lang=js&"
import style0 from "./SelectCategoryItem.vue?vue&type=style&index=0&id=becda7ec&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "becda7ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VIcon,VSheet})
