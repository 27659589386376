<template lang="pug">
v-app
  v-progress-linear(:active="loading" :indeterminate="loading" absolute top color="secondary")
  transition(:name="$isFFBMode() ? 'none' : 'fade'")
    router-view
  re-snackbar
  re-confirm
  re-connection-warning
  ios-system-font(v-pre)
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { changeLocale } from './i18n'
import Snackbar from './components/utils/Snackbar.vue'
import Confirm from './components/utils/Confirm.vue'
import ConnectionWarning from './components/utils/ConnectionWarning.vue'

export default {
  name: 'App',

  components: {
    're-snackbar': Snackbar,
    're-confirm': Confirm,
    're-connection-warning': ConnectionWarning,
  },

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister,
    }),

    loading() {
      return this.cashRegister === null && this.$route.name !== 'SignIn'
    },
  },

  created() {
    changeLocale()

    if (this.$isCordova()) {
      window.cordova.getAppVersion.getVersionNumber().then((version) => {
        this.setCordovaBuildVersion(version)
      })

      // App launch count for review
      const appLaunchCount = parseInt(localStorage.getItem('rekassa.kz-tools-appLaunchCount') || 0, 10)
      localStorage.setItem('rekassa.kz-tools-appLaunchCount', appLaunchCount + 1)

      // Only for Android
      if (this.$isAndroid()) {
        // Check NFC and Android 8 above support for m4bank
        this.m4bankCheckCompatibility()
      }

      // Enable Bluetooth for FFBPOS
      if (this.$isFFBMode()) {
        window.cordova.plugins.rekassaEscPos.enableBluetooth(() => {}, () => {})
      }
    }
  },

  mounted() {
    // Use system font size on iOS
    if (this.$isCordova() && window.device.platform === 'iOS') {
      const iosSystemFontSize = getComputedStyle(document.getElementsByTagName('ios-system-font')[0]).getPropertyValue('font-size')
      let css = `html, body, .v-application, .v-input, .v-label { font-size: ${iosSystemFontSize} !important; }`
      if ((`${iosSystemFontSize}`).replace(/\D/g, '') >= 28) {
        css += ' .v-input input { max-height: none !important; }'
      }
      const head = document.head || document.getElementsByTagName('head')[0]
      const style = document.createElement('style')

      head.appendChild(style)

      style.type = 'text/css'
      if (style.styleSheet) {
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
    }

    // Hide Splash screen
    if (this.$isCordova()) {
      if (window.device.platform === 'iOS') {
        setTimeout(() => {
          navigator.splashscreen.hide()
        }, 3000)
      } else {
        navigator.splashscreen.hide()
      }
    }
  },

  methods: {
    ...mapActions({
      setCordovaBuildVersion: 'tools/setCordovaBuildVersion',
      m4bankCheckCompatibility: 'm4bank/checkCompatibility',
      analyticsSetUserProperty: 'analytics/setUserProperty',
    }),
  },
}
</script>
