<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("активация_кассы") }}
    v-progress-linear(:active="loading" :indeterminate="loading" fixed top color="secondary")

  v-main(flat v-if="cashRegister")
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext
            v-subheader {{ $t("паспорт") }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("знм") }}
                v-list-item-subtitle {{ $t("заводской_номер") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1
                  v-btn.mr-1(icon @click="copy(cashRegister.serialNumber)")
                    v-icon mdi-content-copy
                  | {{ cashRegister.serialNumber }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("модель") }}
                v-list-item-subtitle {{ $t("контрольно-кассовой_машины") }}
              v-list-item-action(v-if="cashRegister")
                v-list-item-action-text.title {{ cashRegister.model }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("год_выпуска") }}
              v-list-item-action(v-if="cashRegister")
                v-list-item-action-text.title {{ cashRegister.year }}

            div.mt-2.pa-2
              v-alert(color="yellow darken-2" dark icon="mdi-alert" dense text outlined)
                .subtitle {{ $t("для_активации_кассы_dotdotdot") }}. &nbsp;
                  a.blinker(@click.prevent="howToActivate()") {{ $t("подробнее") }}

            v-subheader.my-8.red--text(v-if="serialNumberError" v-html="serialNumberError")
            v-form.px-4(ref="activationForm" @submit.prevent="activate()")
              v-select(:label="$t('офд')" v-model="fdo" :items="fdoProviders" item-value="id" item-disabled="disabled" :messages="getFdoTerms()" :rules="[rules.required]")
                template(slot="selection" slot-scope="data") {{ locale === 'ru' ? data.item.name.ru : data.item.name.kk }}
                template(slot="item" slot-scope="data")
                  div.my-3(:class="{'fdo-disabled': data.item.disabled}")
                    .d-inline
                      .title.d-inline {{ locale === 'ru' ? data.item.name.ru : data.item.name.kk }}
                      br
                      .subtitle-2.d-inline {{ locale === 'ru' ? data.item.terms.ru : data.item.terms.kk }}
                      br
                      a(v-if="!data.item.disabled" @click="$openLink(`${locale === 'ru' ? data.item.links.ru : item.links.kk}`, '_system')" disabled).subtitle-2.d-inline Подробнее
              v-text-field(:label="$t('инм_id')" v-model="id" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" @input="idError = null" :error-messages="idError" validate-on-blur maxlength="9")
              v-text-field(:label="$t('токен')" v-model="token" type="tel" :rules="[rules.required, rules.numbersOnly]" autocomplete="off" @input="tokenError = null" :error-messages="tokenError" validate-on-blur maxlength="9")
              v-text-field(:label="$t('придумайте_пин-код')" v-model="pincode" type="tel" :rules="[pincodeRules.length, rules.numbersOnly]" autocomplete="off" maxlength="4" validate-on-blur)
              v-text-field(:label="$t('повторите_пин-код')" v-model="pincodeRepeat" type="tel" :rules="[pincodeRules.length, rules.numbersOnly, pincodeRules.same(pincodeRepeat, pincode)]" autocomplete="off" maxlength="4" validate-on-blur)
              v-btn.my-3(color="primary" block :disabled="loading" @click="activate()") {{ $t("активировать") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n, { getLocale } from '../../i18n/index'

export default {
  data: () => ({
    loading: false,

    cashRegister: null,

    fdo: null,
    id: null,
    token: null,
    pincode: null,
    pincodeRepeat: null,

    idError: null,
    tokenError: null,
    serialNumberError: null,

    fdoProviders: null,

    pincodeRules: {
      length: (v) => (!!v && v.length === 4) || i18n.t('требуется_4-х_значный_пин-код'),
      same: (pin, v) => (!!v && v === pin) || i18n.t('пин-код_не_совпадает'),
    },
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      currentCashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    this.loading = true
    this.fetchCashRegister(this.$route.params.newCashRegisterId).then((data) => {
      this.cashRegister = data.data.cashRegister
      if (this.cashRegister.status !== 'NEW') this.$router.push('/')
      this.fetchFdoProviders().then((data2) => {
        this.loading = false
        this.fdoProviders = data2.data.map((item) => {
          item.disabled = !item.enabled
          return item
        })
      }).catch(() => {
        this.loading = false
      })
    }).catch(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions({
      fetchCashRegister: 'cashRegisters/fetchCashRegister',
      registerNewCashRegister: 'cashRegisters/registerNewCashRegister',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      setCashRegisterPinChecked: 'cashRegisters/setCashRegisterPinChecked',
      fetchFdoProviders: 'cashRegisters/fetchFdoProviders',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      copyToClipboard: 'tools/copyToClipboard',
    }),

    activate() {
      this.serialNumberError = null
      if (this.$refs.activationForm.validate()) {
        this.loading = true
        const form = new FormData()
        form.append('fdo', this.fdo)
        form.append('id', this.id)
        form.append('token', this.token)
        form.append('password', this.pincode)
        this.registerNewCashRegister({
          cashRegister: this.cashRegister,
          data: form,
        }).then(() => {
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.setCashRegisterPinChecked(false)
            this.loading = false
            this.$router.push('/kkm')
            this.showSnackbar({ message: this.$t('касса_успешно_активирована') })

            this.analyticsLogEvent({ eventName: 're_cash_register_activated', eventProperties: { type: this.fdo, phone: this.user.phone } })
          }).catch(() => {
            this.loading = false
          })
        }).catch((error) => {
          if (error && error.response && error.response.data) {
            if (error.response.data.code === 'UNKNOWN_ID') {
              this.idError = this.$t('backend/UNKNOWN_ID')
            } else if (error.response.data.code === 'INVALID_TOKEN') {
              this.tokenError = this.$t('backend/INVALID_TOKEN')
            } else if (error.response.data.code === 'CASH_REGISTER_ALREADY_ACTIVATED') {
              this.idError = this.$t('backend/CASH_REGISTER_ALREADY_ACTIVATED')
            } else if (error.response.data.code === 'CASH_REGISTER_WRONG_SERIAL_NUMBER') {
              this.serialNumberError = this.$t('backend/CASH_REGISTER_WRONG_SERIAL_NUMBER', { serverSerialNumber: error.response.data.meta.SERVER_SERIAL_NUMBER, serialNumber: error.response.data.meta.SERIAL_NUMBER })
            }
          }
          this.loading = false
        })
      }
    },

    copy(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },

    getFdoTerms() {
      let terms = null
      if (this.fdoProviders) {
        this.fdoProviders.forEach(item => {
          if (item.id === this.fdo) {
            terms = (this.locale === 'ru' ? item.terms.ru : item.terms.kk)
          }
        })
      }
      return terms
    },

    howToActivate() {
      this.$openLink(`https://link.rekassa.kz/how-2-activate-manual-${this.locale}`, '_system')
    },
  },
}
</script>

<style lang="stylus">
</style>
